import React, {Dispatch, SetStateAction} from "react";
import {Config} from "../../SettingsView";
import WhatsAppChat from "../../../../Components/WhatsAppChat/WhatsAppChat";


interface Props {
    personalizedBotAnswer: Config | undefined
    setPersonalizedBotAnswer: Dispatch<SetStateAction<Config | undefined>>
    handleSettingChange: (newValue: boolean | string, setting: Config) => Promise<void>
}

const PersonalizedBotMessageModal = ({personalizedBotAnswer, setPersonalizedBotAnswer, handleSettingChange}: Props) => {

    return (
        <>
            {personalizedBotAnswer ?
                <div className="modal-container flex-column justify-content-between">
                    <WhatsAppChat botMessage={personalizedBotAnswer.setting_value.toString()}/>
                    <form className={'mt-1'} onSubmit={() => {
                        handleSettingChange(personalizedBotAnswer.setting_value, personalizedBotAnswer)
                    }}>
                        <div>
                            <label htmlFor="name">Bot Antwort</label>
                            <input required type="text" id="name" placeholder={personalizedBotAnswer.setting_value.toString()} value={personalizedBotAnswer.setting_value.toString()} onChange={(e)=>{
                                setPersonalizedBotAnswer({
                                    ...personalizedBotAnswer,
                                    setting_value: e.target.value
                            })
                                
                            }}
                            />
                        </div>
                        {/*  <div className="errorMessage">
                    {errorMessage}
                    <br/>
                    {errorMessage && (<ExclamationDiamond color={"#DB2742"} size={".8em"}/>)}
                </div>*/}
                        <button className={"modal-container-button"} type={"submit"}>Speichern</button>
                    </form>
                </div> : null}
        </>
    )
}

export default PersonalizedBotMessageModal