import React, {useContext, useEffect, useState} from "react";
import './TierListView.css'
import HeadNavigation from "../../Components/HeadNavigation/HeadNavigation";
import {FeatureCard} from "../../Components/FeatureCard/FeatureCard";
import {TierCard} from "../../Components/TierCard/TierCard";
import {AccountTier, Api} from "../../RequestsProvider/RequestProvider";


interface InstanceStats {
    id: number,
    instanceName: string,
    createdAt: string,
    cpuUtilization: number,
    memoryUtilization: number
}

export const TierListView = () => {

    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [tierList, setTierList] = useState<AccountTier[]>()

    const request = useContext(Api)
    const {getTierList} = request!

    useEffect(() => {
        getTierList().then(tl => {
            setTierList(tl.message)
            setIsLoading(false)
        })
    }, []);
    return (
        <>
            <HeadNavigation path={[{path: "/", pathName: "Startseite"}, {path: null, pathName: "Account Stufen"}]}/>
            <div className="container">
                <div className="row mt-4">
                    <h1 className="text-center">Pick the best plan for you</h1>
                    {tierList ? <>
                        <div className="col-12 col-lg-4">
                            <TierCard accountTier={tierList[0]}/>
                        </div>
                        <div className="col-12 col-lg-4">
                            <TierCard accountTier={tierList[2]}/>
                        </div>
                        <div className="col-12 col-lg-4">
                            <TierCard accountTier={tierList[1]}/>
                        </div>
                    </> : null}
                </div>
            </div>
        </>
    )
}