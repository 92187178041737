import React, {useContext, useEffect, useState} from 'react';
import './App.css';
import {MainPage} from "./Views/MainPage/MainPage";
import {Route, Routes} from "react-router-dom";
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import 'bootstrap/dist/css/bootstrap.min.css'
import {GetAPIKey} from "./Views/GetAPIKey/GetAPIKey";
import HeaderView from "./Views/HeaderView/HeaderView";
import {BarLoader} from "react-spinners";
import {Context} from "./ContextProvider/Context";
import HeadNavigation from "./Components/HeadNavigation/HeadNavigation";
import {BirthdayView} from "./Views/BirthdayView/BirthdayView";
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";
import {PersonView} from "./Views/PersonView/PersonView";
import {SettingsView} from "./Views/SettingsView/SettingsView";
import {Api, ApiAuthentication} from "./RequestsProvider/RequestProvider";
import {QrCodeView} from "./Views/QrCodeView/QrCodeView";
import {TierListView} from "./Views/TierListView/TierListView";

function App() {

    // localStorage.removeItem('apiKey')
    const [apiKey] = useState(localStorage.getItem('apiKey') ?? null)
    const [apiCredentials, setApiCredentials] = useState<ApiAuthentication | null>(null)

    const context = useContext(Context)
    const request = useContext(Api)

    useEffect(() => {
        getApiKeyInformation().then(apiCredentials=>{
            console.log(apiCredentials)
            setApiCredentials(apiCredentials.message)
        })
    },[])


    if (!context || !request) {
        return (<></>)
    }

    const {contentLoading} = context
    const {getApiKeyInformation} = request
    const renderLoading = () => {
        return contentLoading ? <BarLoader
            color={'#4ceb9c'}
            height={3}
            width={"100%"}
            cssOverride={{backgroundColor: "transparent", position: 'absolute'}}
            speedMultiplier={.8}
        /> : null
    }


    return apiKey ? (
        apiCredentials?.isAuthenticated === "true" ? <>
            <HeaderView profilePictureURL={apiCredentials.profilePictureURL}/>
            <div className="grid-container">
                {renderLoading()}
                <Routes>
                    <Route path="/" element={<MainPage apiKey={apiKey}/>}/>
                    <Route path="/personen" element={<BirthdayView apiKey={apiKey}/>}/>
                    <Route path="/tierList" element={<TierListView/>}/>
                    <Route path="/einstellungen" element={<SettingsView apiKey={apiKey}/>}/>
                    <Route path="/personen/p/:id" element={<PersonView/>}/>
                    <Route
                        path="*"
                        element={
                            <>
                                <HeadNavigation path={[{path: "/", pathName: "Startseite"}]}/>
                                <div className="text-center m-5 p-5">
                                    <h2>404 Site not found </h2>
                                </div>
                            </>
                        }
                    />
                </Routes>
            </div>
            <ToastContainer
                position="bottom-center"
                autoClose={3000}
                newestOnTop={true}
                closeOnClick
                theme='light'
                pauseOnHover={false}
            />
        </> : <QrCodeView apiCredentials={apiCredentials} setApiCredentials={setApiCredentials}/>
    ) : <GetAPIKey/>
}

export default App;
