import "./HeaderView.css"
import MenuView from "./MenuView/MenuView";
import React, {CSSProperties, FC, useContext, useEffect, useState} from "react";
import {Power, CircleFill, StarFill} from "react-bootstrap-icons";
import RandomSVGPicture from "../../Components/RandomSVGPicture/RandomSVGPicture";
import {Api, ApiAuthentication} from "../../RequestsProvider/RequestProvider";
import moment from "moment";

interface Props {
    profilePictureURL?: string
}

const usePageVisibility = () => {
    const [isVisible, setIsVisible] = useState(document.visibilityState === 'visible')

    useEffect(() => {
        const handleVisibilityChange = () => {
            setIsVisible(document.visibilityState === 'visible')
        }

        document.addEventListener('visibilitychange', handleVisibilityChange)

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange)
        }
    }, [])

    return isVisible
}

const HeaderView = ({profilePictureURL}: Props) => {


    const [showUserMenu, setShowUserMenu] = useState(false)
    const [name, setName] = useState<string | null>(localStorage.getItem('apiKeyName'))
    const [apiKeyInformation, setApiKeyInformation] = useState<ApiAuthentication>()
    const isPageVisible = usePageVisibility()

    const request = useContext(Api)

    const {updatedAt, getUpdatedAt, getApiKeyInformation} = request!

    useEffect(() => {
        if (showUserMenu) {
            setTimeout(() => {
                setShowUserMenu(!showUserMenu)
            }, 4000)
        }
    }, [showUserMenu])
    useEffect(() => {
        if (isPageVisible) {
            getUpdatedAt()
        }
    }, [isPageVisible])
    useEffect(() => {
        console.log(updatedAt)
    }, [updatedAt])
    useEffect(() => {
        getApiKeyInformation().then(userInfo => {
            setApiKeyInformation(userInfo.message)
        })
    }, [])
    const cssOverride: CSSProperties = {
        display: "block",
        marginTop: "20px",
        borderColor: "red",
    };

    const signOut = () => {
        localStorage.removeItem('apiKey')
        localStorage.removeItem('apiKeyName')
        window.location.reload()
    }


    return (
        <>
            <div className="header">
                {<MenuView/>}
                <div className="user-info">
                    {apiKeyInformation ?
                        <div
                            className={`row d-flex align-content-center acc-tier acc-${apiKeyInformation.tier.tierName}`}>
                            <small className="col-12 name d-flex justify-content-end"><StarFill
                                className={"d-flex align-content-center"}
                                size={13}/>&nbsp;{apiKeyInformation.tier.tierName.toUpperCase()}</small>

                        </div> : <div
                            className={`row d-flex align-content-center acc-tier acc-free`}>
                            <small className="col-12 name d-flex justify-content-end"><StarFill
                                className={"d-flex align-content-center"}
                                size={13}/>&nbsp;LÄDT...</small></div>
                    }
                    <div className="padding-left">
                        <div className="col-12 name d-flex justify-content-end">{name}</div>
                        <div className="col-12 d-flex justify-content-end"><small
                            className={''}>Bot {moment().diff(updatedAt, 'minutes') > 15 ? 'offline' : 'online'}&nbsp;
                            <CircleFill
                                className={`${moment().diff(updatedAt, 'minutes') > 15 ? 'offline' : 'online'} pab-1`}
                                size={11}/></small>
                        </div>
                        {moment().diff(updatedAt, 'minutes') > 15 ?
                            <div className="col-12 d-flex justify-content-end position-relative pb-1"><small
                                className={'position-absolute'}>seit {moment(updatedAt).format('DD.MM.yyyy hh:ss')}</small>
                            </div> : null}
                    </div>
                    <div style={{position: "relative"}}>
                        <div className="user-picture" onClick={() => {
                            setShowUserMenu(!showUserMenu)
                        }}>
                            {profilePictureURL ? <div className="d-flex align-items-center">
                                <div className="circlef-colored">
                                    <img src={profilePictureURL} alt=""
                                         className="img-fluid rounded-circle"/>
                                </div>
                            </div> : <RandomSVGPicture {...{width: 45, height: 45}}/>}
                        </div>
                        {showUserMenu && (
                            <div className="user-menu-container" onClick={signOut}>
                                <div><Power></Power></div>
                                <button>Logout</button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default HeaderView
