import {Context} from "./Context"
import {useState} from "react";

export const ContextProvider = ({ children }: any) => {

    const [contentLoading, setContentLoading] = useState(false)

    return (
        <Context.Provider value={{ contentLoading, setContentLoading }}>
            {children}
        </Context.Provider>
    )
}