import {useRef} from "react";
import './Modal.css'
import * as React from "react";
import {useOnClickOutside} from "usehooks-ts";

const Modal = ({showModal, setShowModal, content, bgColor}: any) => {

    const modalRef = useRef(null)
    useOnClickOutside(modalRef, () => {
        setShowModal(false);
    })

    return <>
        {showModal && (
            <div className="overlay">
                <div ref={modalRef} className="modal" style={{backgroundColor: bgColor}}>
                    <div className="position-absolute top-0 end-0 p-4 pointer" onClick={() => {
                        setShowModal(false);
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                             width="25" height="25"
                             viewBox="0 0 50 50">
                            <path
                                d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"></path>
                        </svg>
                    </div>
                    {content}
                </div>
            </div>
        )}
    </>
}

export default Modal
