import React from "react";
import Switch from "react-switch";
import {Config} from "../../Views/SettingsView/SettingsView";

const CSwitch = (props: {
    checked: boolean
    onChange: (newValue: boolean, setting: Config) => Promise<void>
    setting: Config
    disabled?: boolean
}) => {

    return (
        <Switch
            checked={props.checked}
            disabled={props.disabled}
            onChange={(newValue) => props.onChange(newValue, props.setting)}
            onColor="#86d3ff"
            offColor="#e58e99"
            offHandleColor="#dc3545"
            onHandleColor="#2693e6"
            handleDiameter={20}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={10}
            width={33}
            className="react-switch"
            id="material-switch"
        />
    )
}

export default CSwitch
