import "./FeatureCard.css";
import {Icon} from "react-bootstrap-icons";

interface Props {
    name: string;
    description: string;
    icon: Icon;
    path: string;
    disabled?: boolean
}

export const FeatureCard = ({name, description, icon: IconComponent, path, disabled}: Props) => {
    return (
        <a href={!disabled? path : "#"} className={`m-2 border-custom ${disabled? 'disabled' : null}`}>
            <div className="row mb-4">
                <div className="col-12 d-flex justify-content-end">
                    <IconComponent className={`m-3 ${disabled? 'color-disabled' : 'text-secondary'}`} size={30}/>
                </div>
            </div>
            <div className="row m-2 mb-3">
                <div className="col-12">
                    <h5>{name}</h5>
                </div>
                <div className="col-12 m-0 text-truncate">
                    <p className={"description"}>{description}</p>
                </div>
            </div>
        </a>
    );
};
