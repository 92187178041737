export enum SettingKey {
    transcribeWithBotMessage = 'transcribeWithBotMessage',
    enableFactAPI = 'enableFactAPI',
    silentMode = 'silentMode',
    transcribeGroupMessages = 'transcribeGroupMessages',
    enableDailyBirthdayChecker = 'enableDailyBirthdayChecker',
    enableDailyGarbageChecker = 'enableDailyGarbageChecker',
    useGPTForGarbageMessage = 'useGPTForGarbageMessage',
    enableBotMentioned = 'enableBotMentioned',
    useChatGPT = 'useChatGPT',
    transcribeAudios = 'transcribeAudios',
    personalizedBotAnswer = 'personalizedBotAnswer'
}
interface SettingDescription{
    name : string,
    description: string
}
export const description = (setting_key : SettingKey): SettingDescription=>{
    switch (setting_key) {
        case SettingKey.enableBotMentioned:
            return {
                name: "Boterwähnungen",
                description: `Wenn der Chatpartner das Wort "bot" erwähnt, antwortet der Bot mit einer KI generierten Antwort.`
            }
        case SettingKey.transcribeAudios:
            return {
                name: "Aktiviere Transkriptionen",
                description: "Der Bot transkribiert Sprachnachrichten."
            }
        case SettingKey.enableDailyBirthdayChecker:
            return {
                name: "Geburtstagsgrüße",
                description: "Der Bot gratuliert um 6 Uhr zum Geburtstag."
            }
        case SettingKey.enableDailyGarbageChecker:
            return {
                name: "Müllerinnerung",
                description: "Der Bot erinnert um 19 Uhr an morgige Müllabfuhr."
            }
        case SettingKey.enableFactAPI:
            return {
                name: "Fakten API",
                description: `"bot fact" sendet einen zufälligen Fakt.`
            }
        case SettingKey.silentMode:
            return {
                name: "Stumm Modus",
                description: "Alle Nachrichten werden vom Bot ignoriert."
            }
        case SettingKey.transcribeGroupMessages:
            return {
                name: "Transkribiere Sprachnachrichten in Gruppen",
                description: "Sprachnachrichten in Gruppen werden transkribiert"
            }
        case SettingKey.transcribeWithBotMessage:
            return {
                name: "Transkribiere mit Botnachricht",
                description: "Bevor die Nachricht der Transkription kommt, wird der Bot mit einer Nachricht antworten."
            }
        case SettingKey.useChatGPT:
            return {
                name: "Nutze ChatGPT",
                description: ""
            }
        case SettingKey.useGPTForGarbageMessage:
            return {
                name: "Nutze ChatGPT Nachricht für Müllerinnerung",
                description: "Der Bot wird eine KI generierte Erinnerung verwenden."
            }
        case SettingKey.personalizedBotAnswer:
            return {
                name: "Bot Antwort",
                description: "Antwort, mit welcher der Bot auf Nachrichten reagiert."
            }
        default:
            return {
                name: "",
                description: ""
            }
    }
}