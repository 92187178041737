import { slide as Menu } from 'react-burger-menu'
import {List} from 'react-bootstrap-icons'
import "./MenuView.css"
const MenuView = ()=>{

    return (
        <Menu customCrossIcon={false} customBurgerIcon={<List/>} width={ 300 }>
            <div className="navigation">Navigation</div>
            <a id="home" className="menu-item" href="/">Startseite</a>
            <a id="about" className="menu-item" href="/personen">Personen</a>
            <a id="about" className="menu-item" href="/muell">Müll</a>
            <a id="einstellungen" className="menu-item" href="/einstellungen">Einstellungen</a>
        </Menu>
    )
}

export default MenuView
