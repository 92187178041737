import {ArrowRightShort} from "react-bootstrap-icons";
import "./HeadNavigation.css"
import {useContext} from "react";
import {Context} from "../../ContextProvider/Context";

interface Path {
    pathName: string | null;
    path: string | null;
}
interface Props {
    path: Path[]
}
const HeadNavigation = ({path}: Props) => {
    return (
        <div className="head-navigation">
            {path.map((value, index)=>{
                return (
                    <div key={index} className={"head-nav-div"}>{index > 0 && index < path.length? <ArrowRightShort/> : null}{value.path? <a href={value.path}>{value.pathName}</a> :<span>{value.pathName}</span> }
                    </div>
                )
            })}
        </div>
    )
}

export default HeadNavigation
