import React, {useEffect, useState} from "react";
import './MainPage.css'
import {Whatsapp, GearWide, Trash3, BalloonHeart, PersonCircle, StarFill} from "react-bootstrap-icons";
import HeadNavigation from "../../Components/HeadNavigation/HeadNavigation";
import {FeatureCard} from "../../Components/FeatureCard/FeatureCard";


interface InstanceStats {
    id: number,
    instanceName: string,
    createdAt: string,
    cpuUtilization: number,
    memoryUtilization: number
}

interface Props {
    apiKey: string
}

export const MainPage = ({apiKey}: Props) => {

    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [instanceData, setInstanceData] = useState<InstanceStats[] | []>([])

    return (
        <>
            <HeadNavigation path={[{path: null, pathName: "Startseite"}]}/>
            <div className="container">
                <div className="row mt-4">
                    <div className="col-12 col-lg-4">
                        <FeatureCard description={"Füge Personen und Geburtstage hinzu"} icon={PersonCircle}
                                     name={"Personen"}
                                     path={"/personen"}/>
                    </div>
                    <div className="col-12 col-lg-4">
                        <FeatureCard description={"Füge Müllerinnerungen hinzu"} icon={Trash3} name={"Müll"}
                                     path={"/muell"} disabled/>
                    </div>
                    <div className="col-12 col-lg-4">
                        <FeatureCard description={"Upgrade deinen Account auf die nächste Stufe"} icon={StarFill}
                                     name={"Account Stufen"} path={"/tierList"}/>
                    </div>
                    <div className="col-12 col-lg-4">
                        <FeatureCard description={"Verwalte die Einstellungen des WhatsApp Bots"} icon={GearWide}
                                     name={"Einstellungen"} path={"/einstellungen"}/>
                    </div>
                </div>
            </div>
        </>
    )
}