import React, {useState, useEffect} from 'react';
import moment, {min} from 'moment';

const QuarterHourTimer = () => {
    const [timeRemaining, setTimeRemaining] = useState('');

    const calculateTimeRemaining = () => {
        const now = moment();
        const nextQuarter = moment().startOf('minute');

        if (now.minutes() % 15 === 0 && now.seconds() === 0) {
            nextQuarter.add(15, 'minutes');
        } else {
            nextQuarter.minutes(Math.ceil(now.minutes() / 15) * 15).seconds(0);
        }

        const duration = moment.duration(nextQuarter.diff(now));
        let minutes = Math.floor(duration.asMinutes());
        let seconds = duration.seconds();

        if(minutes<0){
            minutes = 14
        }
        if(seconds < 0){
            seconds = seconds* (-1)
        }
        setTimeRemaining(`${minutes < 10? '0'+minutes: minutes}:${seconds < 10? '0'+seconds: seconds}`);
    };

    useEffect(() => {
        calculateTimeRemaining();
        const timer = setInterval(calculateTimeRemaining, 1000);
        return () => clearInterval(timer); // Cleanup on unmount
    }, []);

    return (
        <span>{timeRemaining}</span>
    );
};

export default QuarterHourTimer;