import "./CButton.css"
import {MouseEventHandler} from "react";
const CButton = (props:{
    icon?:any,
    content:any,
    onClick:MouseEventHandler,
    disabled?: boolean
}) => {

    return (
        <button disabled={props.disabled} className={"c-button"} onClick={props.onClick}><div>{props.content}</div> {props.icon}</button>
    )
}

export default CButton
