import "./WhatsAppChat.css"
import {PlayFill, MicFill} from "react-bootstrap-icons";
import moment from "moment";
import {useContext, useEffect, useState} from "react";
import {Api} from "../../RequestsProvider/RequestProvider";

interface Props {
    botMessage : string |undefined
}
const WhatsAppChat = ({botMessage} : Props) => {

    const _userName = localStorage.getItem('apiKeyName')??"benutzer"
    const userName = _userName.charAt(0).toUpperCase() + _userName.slice(1)
    const [fact, setFact] = useState<string>()

    const request = useContext(Api)
    const {getFact} = request!


    useEffect(() => {
        getFact().then(fact=>{
            if(fact.type === 'fact'){
                setFact(fact.message)
            }
        })
    }, []);
    return (
        <div className={"wa_bg p-0 p-lg-3 mt-5 mt-lg-0"}>
            {fact ? <div className={'container'}>
                <div className="msg first">
                    <div className="bubble">
                        <div className="txt">
                            <span className="name">Mike</span>
                            <span className="timestamp">{moment().format('h:mm A')}</span>
                            <span className="message">{fact}</span>
                        </div>
                        <div className="bubble-arrow"></div>
                    </div>
                </div>


                <div className="msg second">
                    <div className="bubble">
                        <div className="txt">
                            <div className="c-wa-audio">
                                <div className="c-wa-audio__wrapper">
                                    <div className="c-wa-audio__photo-container">
                                        <div className="c-wa-audio__photo"></div>
                                        <MicFill className={"c-wa-audio__photo-mic"} size={20}/>
                                    </div>
                                    <div className="c-wa-audio__control-container">
                                        <PlayFill className={"c-wa-audio__control-play"} size={30}/>
                                    </div>
                                    <div className="c-wa-audio__time-container">
                                        <span className="c-wa-audio__time-current">0:00</span>
                                        <div className="c-wa-audio__time-slider" data-direction="horizontal">
                                            <div className="c-wa-audio__time-progress">
                                                <div className="c-wa-audio__time-pin" id="progress-pin"
                                                     data-method="rewind"></div>
                                            </div>
                                        </div>
                                        <span className="c-wa-audio__time-total">3:15</span>
                                    </div>
                                </div>
                                <audio>
                                    <source
                                        src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/355309/Swing_Jazz_Drum.mp3"
                                        type="audio/mpeg"/>
                                </audio>
                            </div>
                        </div>
                        <div className="bubble-arrow"></div>
                    </div>
                </div>


                <div className="msg third">
                    <div className="bubble alt">
                        <div className="txt">
                            <span className="name alt">{userName}</span>
                            <span className="timestamp">{moment().format('h:mm A')}</span>
                            <p className="message alt"><span className={'fst-italic'}>{botMessage}</span> 🤖<br/>Transkribiere
                                Audio...</p>
                        </div>
                        <div className="bubble-arrow alt"></div>
                    </div>
                </div>
            </div> : null}
        </div>
    )
}

export default WhatsAppChat
